import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, VFC } from 'react';
import * as yup from 'yup';

import usePrevious from '../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { PageGridSectionActions } from '../../../../../design-system/body-sections/PageGridSectionActions';
import { Account } from '../../../../../types/domain/account/account';
import { SubscriptionFormValues } from './SubscriptionSection.model';
import { SubscriptionSectionEdit } from './SubscriptionSectionEdit';
import { SubscriptionSectionReadonly } from './SubscriptionSectionReadonly';

const SubscriptionSchema = yup.object().shape({
  name: yup.string().required('Account Name is Required.'),
  licenseCount: yup
    .number()
    .positive()
    .required('License Count is Required.')
    .label('License Count'),
});

export type Props = {
  readonly canEdit: boolean;
  readonly account: Account;
  readonly onSubmit: (formValues: SubscriptionFormValues) => void;
  readonly isSubmitting: boolean;
  readonly submitError: apiTypes.GenericServerError | null;
};

export const SubscriptionSection: VFC<Props> = ({
  canEdit,
  account,
  onSubmit,
  isSubmitting,
  submitError,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const previousIsSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (!isSubmitting && previousIsSubmitting) {
      setIsEditing(false);
    }
  }, [isSubmitting, previousIsSubmitting]);

  const formik = useFormik<SubscriptionFormValues>({
    validationSchema: SubscriptionSchema,
    enableReinitialize: true,
    initialValues: {
      licenseCount: account.licenseCount,
      licenseType: account.licenseType,
      name: account.name,
      externalLicenses: account.externalLicenses ?? [],
    },
    onSubmit: val => onSubmit(val),
  });

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        title="Subscription Information"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        error={submitError && <ServerErrorAlert title="Error" error={submitError} />}
        actions={
          canEdit ? (
            <PageGridSectionActions
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              onEdit={() => setIsEditing(true)}
              onCancel={() => setIsEditing(false)}
              canEdit={canEdit}
            />
          ) : undefined
        }
        content={
          isEditing ? (
            <SubscriptionSectionEdit account={account} />
          ) : (
            <SubscriptionSectionReadonly account={account} />
          )
        }
      />
    </FormikProvider>
  );
};
