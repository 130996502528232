import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, VFC } from 'react';

import usePrevious from '../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { PageGridSectionActions } from '../../../../../design-system/body-sections/PageGridSectionActions';
import { AccountType } from '../../../../../types/domain/account/constants';
import { LimitedTrait } from '../../../../../types/domain/trait/LimitedTrait';
import { ProductTraitsFormValues } from './ProductTraitsSection.model';
import { ProductTraitsSectionEdit } from './ProductTraitsSectionEdit';
import { ProductTraitsSectionReadonly } from './ProductTraitsSectionReadonly';

export type Props = {
  readonly canEdit: boolean;
  readonly accountTraits: LimitedTrait[];
  readonly accountType: AccountType | null;
  readonly onSubmit: (formValues: ProductTraitsFormValues) => void;
  readonly isSubmitting: boolean;
  readonly submitError: apiTypes.GenericServerError | null;
};

export const ProductTraitsSection: VFC<Props> = ({
  canEdit,
  accountTraits,
  accountType,
  onSubmit,
  isSubmitting,
  submitError,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const previousIsSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (!isSubmitting && previousIsSubmitting) {
      setIsEditing(false);
    }
  }, [isSubmitting, previousIsSubmitting]);

  const formik = useFormik<ProductTraitsFormValues>({
    enableReinitialize: true,
    initialValues: {
      traitCodes: accountTraits.map(trait => trait.code),
    },
    onSubmit: val => onSubmit(val),
  });

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        title="Product Traits"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        actions={
          canEdit ? (
            <PageGridSectionActions
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              onEdit={() => setIsEditing(true)}
              onCancel={() => setIsEditing(false)}
              canEdit={canEdit}
            />
          ) : undefined
        }
        error={submitError && <ServerErrorAlert title="Error" error={submitError} />}
        content={
          isEditing ? (
            <ProductTraitsSectionEdit accountType={accountType} />
          ) : (
            <ProductTraitsSectionReadonly accountTraits={accountTraits} />
          )
        }
      />
    </FormikProvider>
  );
};
