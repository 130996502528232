import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, VFC } from 'react';

import usePrevious from '../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { PageGridSectionActions } from '../../../../../design-system/body-sections/PageGridSectionActions';
import { User, UserEmailUpdate } from '../../../../../types/domain/user/user';
import { EmailUpdateSchema } from './LoginDetailsSection.model';
import { LoginDetailsSectionEdit } from './LoginDetailsSectionEdit';
import { LoginDetailsSectionReadonly } from './LoginDetailsSectionReadonly';

export type Props = Readonly<{
  onReset: () => void;
  canEdit: Readonly<boolean>;
  user: Readonly<User>;
  isResettingPassword: Readonly<boolean>;
  resetPasswordError: Readonly<apiTypes.GenericServerError | null>;
  onResetPassword: () => void;
  isUpdatingEmail: Readonly<boolean>;
  updateEmailError: Readonly<apiTypes.GenericServerError | null>;
  onUpdateEmail: (email: string) => void;
}>;

export const LoginDetailsSection: VFC<Props> = ({
  canEdit,
  user,
  isResettingPassword,
  resetPasswordError,
  onResetPassword,
  isUpdatingEmail,
  updateEmailError,
  onUpdateEmail,
  onReset,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik<UserEmailUpdate>({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: EmailUpdateSchema,
    initialValues: {
      email: user.email,
    },
    onSubmit: val => {
      if (val.email) {
        onUpdateEmail(val.email);
      }
    },
  });

  const previousIsUpdatingEmail = usePrevious(isUpdatingEmail);
  useEffect(() => {
    if (!isUpdatingEmail && previousIsUpdatingEmail && !updateEmailError) {
      setIsEditing(false);
    }
  }, [isUpdatingEmail, previousIsUpdatingEmail, updateEmailError]);

  const { resetForm } = formik;
  const previousIsEditing = usePrevious(isEditing);
  useEffect(() => {
    if (previousIsEditing && !isEditing) {
      resetForm();
      onReset();
    }
  }, [isEditing, onReset, previousIsEditing, resetForm]);

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        title="Login Details"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        error={updateEmailError && <ServerErrorAlert title="Error" error={updateEmailError} />}
        actions={
          canEdit ? (
            <PageGridSectionActions
              isSubmitting={isUpdatingEmail}
              isEditing={isEditing}
              onEdit={() => setIsEditing(true)}
              onCancel={() => setIsEditing(false)}
              canEdit={canEdit}
            />
          ) : undefined
        }
        content={
          isEditing ? (
            <LoginDetailsSectionEdit />
          ) : (
            <LoginDetailsSectionReadonly
              onReset={onReset}
              canEdit={canEdit}
              user={user}
              isResettingPassword={isResettingPassword}
              resetPasswordError={resetPasswordError}
              onResetPassword={onResetPassword}
            />
          )
        }
      />
    </FormikProvider>
  );
};
