import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { apiTypes } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, VFC } from 'react';
import * as yup from 'yup';

import usePrevious from '../../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../../design-system/alert/ServerErrorAlert';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';
import { PageGridSectionActions } from '../../../../../design-system/body-sections/PageGridSectionActions';
import { AccountType } from '../../../../../types/domain/account/constants';
import { Profile, ProfileUpdate } from '../../../../../types/domain/self/profile';
import { ProfileDetailsSectionEdit } from './ProfileDetailsSectionEdit';
import { ProfileDetailsSectionReadonly } from './ProfileDetailsSectionReadonly';

const ProfileUpdateSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  jobFunction: yup.string().nullable().required('Job Function is required'),
});

export type Props = {
  isSubmitting: Readonly<boolean>;
  submitError: Readonly<apiTypes.GenericServerError | null>;
  onSubmit: (data: ProfileUpdate) => void;
  profile: Readonly<Profile>;
  oidcUserAccountType: Readonly<AccountType | 'SYSTEM' | null>;
};

export const ProfileDetailsSection: VFC<Props> = ({
  isSubmitting,
  submitError,
  oidcUserAccountType,
  onSubmit,
  profile,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const previousIsSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (!isSubmitting && previousIsSubmitting) {
      setIsEditing(false);
    }
  }, [isSubmitting, previousIsSubmitting]);

  const formik = useFormik<ProfileUpdate>({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: ProfileUpdateSchema,
    initialValues: {
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      jobFunction: profile.jobFunction,
    },
    onSubmit: val => onSubmit(val),
  });

  const canEdit = useCheckPermissions(
    [permissionsByEntity.User.FULL, permissionsByEntity.GlobalUser.FULL],
    false
  );

  return (
    <FormikProvider value={formik}>
      <PageGridSection
        title="Profile Details"
        FormComponent={Form}
        formComponentProps={{ noValidate: true }}
        actions={
          <PageGridSectionActions
            isSubmitting={isSubmitting}
            isEditing={isEditing}
            onEdit={() => setIsEditing(true)}
            onCancel={() => setIsEditing(false)}
            canEdit={canEdit}
          />
        }
        error={submitError && <ServerErrorAlert title="Error" error={submitError} />}
        content={
          isEditing ? (
            <ProfileDetailsSectionEdit
              profile={profile}
              oidcUserAccountType={oidcUserAccountType}
            />
          ) : (
            <ProfileDetailsSectionReadonly
              profile={profile}
              oidcUserAccountType={oidcUserAccountType}
            />
          )
        }
      />
    </FormikProvider>
  );
};
